<template>
  <div>
    <SiteHeader />
    <div class="tw-mx-auto tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-6 tw-py-14">
      <div class="tw-w-full tw-max-w-lg">
        <div class="tw-mb-8 tw-space-y-2">
          <Heading><slot name="header" /></Heading>
          <Subheading><slot name="sub-header" /></Subheading>
        </div>
        <slot />
      </div>
    </div>
    <SiteFooter :is-error="true" />
    <GetHelp />
    <AppToast />
  </div>
</template>

<script setup lang="ts">
const { applyHead } = useDynamicHead()
applyHead('Casewise')
</script>
